<template>
  <v-text-field
    v-bind="$attrs"
    :type="show ? 'text' : 'password'"
    hint="Password is case sensitive"
    :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
    @click:append="show = !show"
    v-model="password"
    outlined
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    showPassword: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    password: '',
    show: false
  }),
  mounted() {
    this.password = this.value;
    this.show = this.showPassword;
  },
  watch: {
    password() {
      this.$emit('input', this.password);
    }
  }
};
</script>

<style></style>
